"use client"

import { datadogRum, RumErrorEvent } from '@datadog/browser-rum';

if (process.env.NODE_ENV == "production" && typeof window !== "undefined") {
  datadogRum.init({
    applicationId: '5bc18c37-89da-4594-ac97-90a3e3c4e6fe',
    clientToken: 'puba28b6ad6362c2ada45f92f038f53bc4d',
    site: 'datadoghq.com',
    service: 'customer-portal',
    env: 'prod',
    allowedTracingUrls: [
      (url) => url.startsWith("https://api.verticalinsure.com"),
      (url) => url.startsWith("https://customers.verticalinsure.com")
    ],
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: process.env.NEXT_PUBLIC_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event) => {
      if (event.error) {
        const error = (event as RumErrorEvent).error
        // filter out error that might be caused by link validation/ pre-fetch rendering from email clicks
        // https://github.com/DataDog/browser-sdk/issues/2715
        const regex =
          /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/
        if (regex.test(error.message)) return false
      }
      
      return true
    }
  });
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
